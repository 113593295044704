import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import getConfig from 'next/config';

import {
  Box,
  Button,
  DefaultTheme,
  Dropdown,
  Heading,
  Hoverbox,
  Icon,
  Text,
  makeStyles
} from 'groundkeeper-component-library';

import Link from './Link';

import selectLoggedInUser from 'shared/Redux/selectors/user/selectLoggedInUser';
import selectDefaultOrganization from 'shared/Redux/selectors/organization/selectDefaultOrganization';
import { fetchOrganizations } from 'shared/Redux/slices/organization.slice';
import { logout } from 'shared/Redux/slices/auth.slice';
import { wrapper } from 'shared/Redux/store';

const env = getConfig()?.publicRuntimeConfig;

const useStyles = makeStyles((theme: DefaultTheme) => ({
  authenticationArea: {
    '& a': {
      color: theme.color.text.main
    }
  },
  authenticationBox: {
    '& button': {
      marginLeft: 0,
      marginBottom: theme.spacing(2)
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      '& li': {
        marginBottom: theme.spacing(1)
      }
    }
  },
  hoverboxBtn: {
    background: 'transparent',
    border: 0,
    fontSize: '100%',
    fontFamily: 'inherit',
    cursor: 'pointer'
  },
  userEmail: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& a': {
      textDecoration: 'none'
    },
    maxWidth: '20em',
    [theme.mediaQueries.tiny]: {
      display: 'none'
    },
    [theme.mediaQueries.small]: {
      maxWidth: '12em'
    }
  }
}));

function AuthenticationArea() {
  const [loginHoverboxOpen, setLoginHoverboxOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const organization = useSelector(selectDefaultOrganization);
  const router = useRouter();
  const user = useSelector(selectLoggedInUser);

  function handleLogout() {
    setLoginHoverboxOpen(false);
    dispatch(logout());
    router.push('/login');
  }

  if (!organization?.setting_portal_booking_requests) {
    return null;
  }

  return (
    <Box
      className={classes.authenticationArea}
      py={0.5}
      display="flex"
      justifyContent="flex-end"
      columnGap={0}
    >
      {user ? (
        <Link href="/requests">
          <Box p={1} display="flex" columnGap={0.5} alignItems="center">
            <Icon name="list" color="secondary" />
            <Text>
              {intl.formatMessage({
                id: 'requests_list',
                defaultMessage: 'Requests list'
              })}
            </Text>
          </Box>
        </Link>
      ) : null}
      <Link
        href={
          user || organization?.setting_portal_flavor === 'bad_schwartau'
            ? '/requests/create'
            : '/requests/info'
        }
      >
        <Box p={1} display="flex" columnGap={0.5} alignItems="center">
          <Icon name="textbox-plus" color="secondary" />
          <Text>
            {intl.formatMessage({
              id: 'request_create',
              defaultMessage: 'Request create'
            })}
          </Text>
        </Box>
      </Link>
      {user ? (
        <Hoverbox
          id="logout"
          mode="click"
          position="bottom-end"
          open={loginHoverboxOpen}
          borderless
          onOpen={() => setLoginHoverboxOpen(true)}
          onClose={() => setLoginHoverboxOpen(false)}
          closeButton
          target={
            <Box
              className={classes.hoverboxBtn}
              component="button"
              display="flex"
              alignItems="center"
              columnGap={0.5}
              p={1}
            >
              <Icon name="account" color="secondary" />
              <Text className={classes.userEmail}>{user.email}</Text>
              <Icon name={loginHoverboxOpen ? 'arrow-up' : 'arrow-down'} />
            </Box>
          }
        >
          <Box
            className={classes.authenticationBox}
            width="12rem"
            p={1}
            pt={0}
            display="flex"
            flexDirection="column"
          >
            <Heading type="h3">Mein Konto</Heading>
            <Dropdown
              headless
              linkComponent={Link}
              entries={[
                {
                  onClick: handleLogout,
                  title: 'Abmelden'
                }
              ]}
            />
          </Box>
        </Hoverbox>
      ) : (
        <Hoverbox
          id="login"
          mode="click"
          position="bottom-end"
          open={loginHoverboxOpen}
          borderless
          onOpen={() => setLoginHoverboxOpen(true)}
          onClose={() => setLoginHoverboxOpen(false)}
          closeButton
          target={
            <Box
              className={classes.hoverboxBtn}
              component="button"
              display="flex"
              alignItems="center"
              columnGap={0.5}
              p={1}
            >
              <Icon name="supervisor-account" color="secondary" />
              <Text>
                {intl.formatMessage({
                  id: 'login',
                  defaultMessage: 'Login'
                })}
              </Text>
              <Icon name={loginHoverboxOpen ? 'arrow-up' : 'arrow-down'} />
            </Box>
          }
        >
          <Box
            className={classes.authenticationBox}
            width="16rem"
            p={1}
            pt={0}
            display="flex"
            flexDirection="column"
          >
            <Heading type="h3">Anmelden</Heading>
            <Text paragraph color="light">
              Berechtigte Personen können nach der Anmeldung Nutzungszeiten für
              Sportanlagen beantragen. Die Berechtigungen werden durch die
              jeweilige Vergabestelle verwaltet.
            </Text>
            <Link href="/login">
              {/* @ts-ignore: no-typescript-component */}
              <Button fullWidth color="primary" variant="outlined">
                {intl.formatMessage({
                  id: 'login',
                  defaultMessage: 'Login'
                })}
              </Button>
            </Link>
            <ul
              aria-label={intl.formatMessage({
                id: 'links',
                defaultMessage: 'Links'
              })}
            >
              {/* <li>
                <Link href="/register">
                  {intl.formatMessage({
                    id: 'register',
                    defaultMessage: 'Register'
                  })}
                </Link>
              </li> */}
              <li>
                <Link href="/password_reset">
                  {' '}
                  {intl.formatMessage({
                    id: 'password_forgotten',
                    defaultMessage: 'Password forgotten?'
                  })}
                </Link>
              </li>
            </ul>
          </Box>
        </Hoverbox>
      )}
    </Box>
  );
}

AuthenticationArea.getInitialProps = wrapper.getInitialPageProps(
  store => async () => {
    const organizationResult = await store.dispatch(fetchOrganizations(null));

    const organization =
      organizationResult.payload[Object.keys(organizationResult.payload)[0]];

    if (!organization?.setting_portal_booking_requests) {
      return {
        redirect: '/'
      };
    }
  }
);

export default AuthenticationArea;
